﻿import { UrlManager } from "../Utilities/UrlManager";
import { Utilities } from "../Utilities/Utilities";
import { SelectizeHelper } from "../Utilities/SelectizeHelper";
import { ApiClient } from "../ApiClient";
import { CookieManager } from "../Utilities/CookieManager";
import { InViewPort } from "../Utilities/InViewPort";
import { EventTileFlip } from "../Utilities/EventTileFlip";

export class UpcomingList {
    readonly action: string = "GetUpcomingList";
    readonly containerCls: string = "upcoming-page";
    readonly contentCls: string = "upcoming-page-content";
    readonly loadingCls: string = "loading";
    readonly upcomingItemCls :string = "event-card";

    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    selectizeHelper: SelectizeHelper;
    messageManager: IMessageManager;
    cookieManager: ICookieManager;

    filterPrefixConfig: IFilterPrefixConfig;
    controllerUri: string;

    containerElement: HTMLElement = null;

    queryCanBeRun: boolean = false;
	pageID: number = 0;

    constructor(apiClient: IApiClient, utilities: Utilities, urlManager: IUrlManager, selectizeHelper: SelectizeHelper,
                messageManager: IMessageManager, cookieManager: ICookieManager, pageID: number, controllerUri: string,
                filterPrefixConfig: IFilterPrefixConfig) {
        let me: this = this,
            element: JQuery<HTMLElement> = jQuery(`.${me.containerCls}`);

        me.apiClient = apiClient;
        me.utilities = utilities;
        me.urlManager = urlManager;
        me.selectizeHelper = selectizeHelper;
        me.messageManager = messageManager;
        me.cookieManager = cookieManager;

        me.filterPrefixConfig = filterPrefixConfig;
        me.controllerUri = controllerUri;

        if (element.length === 1) {
            me.containerElement = element[0];
            me.queryCanBeRun = true;
            me.pageID = pageID;

            me.initListeners();
            me.initContent();
            me.initScrollEvent();
            me.selectizeHelper.initSelect();

            jQuery(document).ready(() => {
                me.needToLoadNext();
                setTimeout((): void => { me.filtersHasChanged(); me.jumpToPage() }, 200);
            });
        }
    }

    jumpToPage(): void {
        let me: this = this,
            pageElement: JQuery<HTMLElement> = jQuery(me.containerElement).find(`.event-card-container.page-${me.pageID}:first`);

        if (me.pageID > 1 && pageElement.length > 0) {
            me.utilities.scrollToElement(pageElement);
        }
    }

    initScrollEvent():void {
        let me: this = this,
            initAnimation: Function = me.utilities.debounce((): void => {
                me.initAnimation();
            }, 50, false),
            loadMore: Function = me.utilities.debounce((): void => {
                me.needToLoadNext();
            }, 100, false);

        window.onscroll = (ev: UIEvent): any => {
            initAnimation();
            loadMore();
       };
    }

    initContent():void {
        let me:this = this;

        me.initAnimation();
        EventTileFlip.initUpcomingEvents(me.upcomingItemCls);
        me.initPreviousNextListeners();
    }

    initAnimation():void {
        let me:this = this;

        me.utilities.animateIn(`.${me.upcomingItemCls}`);
    }
    initListeners(): void {
        let me: this = this;

        jQuery("#filter-locations").change((): void => {
            me.pageID = 1;

			if (jQuery("#filter-locations").val() != "Online") {
                jQuery("#filter-live-online").prop("checked", false);
                jQuery("#btn-live-online").attr("aria-checked", "false");
			}

            me.filtersHasChanged();
            if (me.queryCanBeRun) {
                me.runQueryAsync();
            }
        });

        jQuery("#filter-categories").change((): void => {
            me.pageID = 1;

            me.filtersHasChanged();
            if (me.queryCanBeRun) {
                me.runQueryAsync();
            }
        });

        jQuery("#btn-live-online").click((): void => {
			me.pageID = 1;

			if (jQuery("#filter-live-online").is(":checked")) {
				me.selectizeHelper.setValue("#filter-locations", "Online", true);
			}

            me.filtersHasChanged();
            if (me.queryCanBeRun) {
                me.runQueryAsync();
            }
        });

        jQuery("#clear-filters").click(function (event:JQuery.Event): void {
            event.preventDefault();
            me.pageID = 1;

            me.clearFilters();
            me.filtersHasChanged();
            me.runQueryAsync();
        });

        jQuery("#filter-timezones").change((): void => {
            me.timeZoneHasChanged();
            me.runQueryAsync();
        });

        me.initPreviousNextListeners();
    }

    initPreviousNextListeners(): void {
        let me: this = this;

        jQuery(".btn.showmore").off("click").click(function (event:JQuery.Event): void {
            event.preventDefault();

            me.pageID++;
            me.runQueryAsync();
        });
    }

    needToLoadNext(): void {
        let me: this = this,
            showMoreBtn: JQuery<HTMLElement> = jQuery(me.containerElement).find(".btn.showmore");
        if (me.queryCanBeRun && showMoreBtn.length === 1) {
            if (InViewPort.isAnyPartOfElementInViewport(showMoreBtn[0])) {
                me.pageID++;
                me.runQueryAsync(false);
            }
        }
    }

    runQueryAsync(showFullScreenLoader: boolean = true): void {
        let me: this = this;

        if (!me.queryCanBeRun) {
            return;
        }

        let loaderContainerElement: JQuery<HTMLElement> = showFullScreenLoader ? jQuery(me.containerElement) : jQuery(me.containerElement).find(".showmore-container"),
            locationFilterElement: JQuery<HTMLElement> = jQuery("#filter-locations"),
            categoryFilterElement: JQuery<HTMLElement> = jQuery("#filter-categories"),
            hiddenLocationElement: JQuery<HTMLElement> = jQuery("#location-name"),
            location: string = locationFilterElement.val() as string || hiddenLocationElement.val() as string,
            locationUri: string = me.selectizeHelper.getUriDataAttributeForSelectedElement(locationFilterElement),
            categoryID: number = categoryFilterElement.val() as number,
            categoryUri: string = me.selectizeHelper.getUriDataAttributeForSelectedElement(categoryFilterElement),
            liveOnline: boolean = jQuery("#filter-live-online").is(":checked"),
            ajaxUrl: string = me.constructAjaxUrl(location, categoryID, liveOnline, me.pageID),
            url: string = me.constructUrl(locationUri, categoryID, categoryUri, liveOnline, me.pageID),
            apiCallback: IApiCallback = {
                success: (data: any): void => {
                    jQuery(me.containerElement).find(`.${me.contentCls}`).html(data.html);
                    me.initContent();
                    me.urlManager.pushState(null, document.title, url);
                },
                complete: (textStatus: string) => {
                    jQuery(loaderContainerElement).removeClass(me.loadingCls);
                },
                error: (textStatus: string, errorThrown: string) => {
                    me.messageManager.showApiErrorMessage(textStatus);
                    me.pageID--;
                },
                scope: me
            };

        jQuery(loaderContainerElement).addClass(me.loadingCls);

        me.apiClient.getData(ajaxUrl, apiCallback);
    }

    constructAjaxUrl(location: string, categoryID: number, liveOnline: boolean, pageID: number): string {
        let me: this = this,
            url: string = `${me.controllerUri}/${me.action}/`,
            parts: string[] = [];

        if (location && location !== "") {
            parts.push("location=" + me.urlManager.encodeUriComponent(location));
        }

        if (categoryID > 0) {
            parts.push("categoryID=" + categoryID);
        }

        if (liveOnline) {
            parts.push("liveOnline=true");
        }

        if (pageID > 1) {
            parts.push("pageID=" + pageID);
        }

        if (parts.length > 0) {
            url += "?" + parts.join("&");
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    constructUrl(location: string, categoryID:number, categoryUri: string, liveOnline: boolean, pageID: number): string {
        let me: this = this,
            url: string = `${me.controllerUri}`;

        // the order of this is important because the routes are set up in the same order

        if (location && location !== "") {
            url += `/${me.filterPrefixConfig.location}-${me.urlManager.encodeUriComponent(location)}`;
        }

        if (categoryID > 0) {
            url += `/${me.filterPrefixConfig.category}-${categoryID}-${me.urlManager.encodeUriComponent(categoryUri)}`;
        }

        if (pageID > 1) {
            url += `/${me.filterPrefixConfig.paging}-${pageID}`;
        }

        if (liveOnline) {
            url += `/${me.filterPrefixConfig.liveOnline}`;
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    clearFilters(): void {
        let me: this = this;

        me.queryCanBeRun = false;

        me.selectizeHelper.setValue("#filter-locations", "", true);
        me.selectizeHelper.setValue("#filter-categories", "", true);
        jQuery("#filter-live-online").prop("checked", false);
        jQuery("#btn-live-online").attr("aria-checked", "false");
        me.pageID = 1;

        me.queryCanBeRun = true;
    }

    filtersHasChanged(): void {
        let me: this = this;

        let location: string = jQuery("#filter-locations").val() as string;
        let categoryID: number = jQuery("#filter-categories").val() as number;
        let liveOnline: boolean = jQuery("#filter-live-online").is(":checked") as boolean;
        let timeZoneID: number = jQuery("#filter-timezones").val() as number;

        let hasLocation: boolean = (location && location !== "");
        let hasCategoryID: boolean = (categoryID > 0);

        me.selectizeHelper.applyClassIf("#filter-locations", "applied", hasLocation);
        me.selectizeHelper.applyClassIf("#filter-categories", "applied", hasCategoryID);

        me.utilities.applyClassIf("#filters", "filter-applied", (hasLocation || hasCategoryID || liveOnline));
    }

    timeZoneHasChanged(): void {
        let me: this = this;

        let timeZoneID: string = jQuery("#filter-timezones").val() as string;

        me.cookieManager.setCookie("TimeZoneID", timeZoneID.toString(), 365);
    }
}

