﻿import { UrlManager } from "../Utilities/UrlManager";
import { Utilities } from "../Utilities/Utilities";
import { SearchList } from "../Pages/SearchList";
import { MessageManager } from "../Utilities/MessageManager";
import { InViewPort } from "../Utilities/InViewPort";
import { SelectizeHelper } from "../Utilities/SelectizeHelper";

export class Schedule {
    readonly containerCls:string = "schedule-container";
    readonly action: string = "GetSchedule";
    readonly eventsAndOAAction: string = "GetEventsAndOADataForSchedule";
    readonly loadingCls:string = "loading";
    readonly resultCls: string = "schedule-page-content";
    readonly templateIDPrefix: string = "template";
    readonly noDatesCls: string = "no-dates";
    readonly hasDatesCls: string = "has-dates";

    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    messageManager: IMessageManager;
    selectizeHelper: SelectizeHelper;
    cookieManager: ICookieManager;

    controllerUri: string;
    isQueryRunning: boolean = false;

    filterPrefixConfig: IFilterPrefixConfig;

    containerElement: HTMLElement = null;
	resultElement: HTMLElement = null;

    pageID: number = 0;

    constructor(cookieManager: ICookieManager, apiClient: IApiClient, utilities: Utilities, urlManager: IUrlManager, selectizeHelper: SelectizeHelper,
        messageManager: IMessageManager, pageID: number, controllerUri: string, filterPrefixConfig: IFilterPrefixConfig) {
        let me:this = this,
            element:JQuery<HTMLElement> = jQuery(`.${me.containerCls}`);

        me.apiClient = apiClient;
        me.utilities = utilities;
        me.urlManager = urlManager;
        me.messageManager = messageManager;
        me.filterPrefixConfig = filterPrefixConfig;
        me.pageID = pageID;
        me.controllerUri = controllerUri;
        me.selectizeHelper = selectizeHelper;
        me.cookieManager = cookieManager;

        if (element.length === 1) {
            me.containerElement = element[0];
            me.needToLoadNext();

            jQuery(document).ready((): void => {
                me.selectizeHelper.initSelect();
                me.initListeners();
                me.initScrollEvent();
                me.initContent();
                me.needToLoadNext();
                setTimeout((): void => { me.filtersHasChanged(false); me.jumpToPage() }, 200);
            });
        }
    }

    initScrollEvent(): void {
        let me: this = this,
            initAnimation: Function = me.utilities.debounce((): void => {
                me.initAnimation();
            }, 50, false),
            loadMore: Function = me.utilities.debounce((): void => {
                me.needToLoadNext();
            }, 100, false);

        window.onscroll = (ev: UIEvent): any => {
            initAnimation();
            loadMore();
        };
    }

    initContent(): void {
        let me: this = this;

        me.initAnimation();
        me.initExpandMobile();
        me.utilities.initPopOpvers();
        me.initShowMoreBtn();
        me.initSlick();
        me.initPreviousNextListeners();
    }

    initSlick(): void {
        let me: this = this,
            config: object = {
                prevArrow: false,
                nextArrow: false,
                infinite: false,
                swipeToSlide: true,
                accessibility: false,
                variableWidth: true
            };

        if (me.utilities.isTouchEnabled()) {
            jQuery('.scheduled-dates').each(function () {
                let dates: JQuery<HTMLElement> = jQuery(this).find(".schedule-date");
                if (dates.length > 0) {
                    dates.addClass("is-in-viewport");
                    jQuery(this).slick(config);
                }
            });
        }
    }

    initAnimation(): void {
        let me: this = this;

        me.utilities.animateIn(".schedule-date");
    }

    initExpandMobile():void {
        let me:this = this;

        jQuery(".schedule-item").off("click").click((event:JQuery.Event):void => {
            me.utilities.expandMobile(event, jQuery(event.currentTarget));
        });
    }

    initListeners(): void {
        let me: this = this;

        jQuery("#filter-locations").change((): void => {
            me.pageID = 1;
            me.getSchedule();
        });

        jQuery("#filter-categories").change((): void => {
            me.pageID = 1;
            me.getSchedule();
        });

        jQuery("#btn-live-online").click((): void => {
            me.pageID = 1;
            me.getSchedule();
        });

        jQuery("#clear-filters").click(function (event: JQuery.Event): void {
            event.preventDefault();
            me.pageID = 1;

            me.clearFilters();
            me.getSchedule();
        });

        jQuery("#filter-timezones").change((): void => {
            me.timeZoneHasChanged();
            me.getSchedule();
        });

        me.initPreviousNextListeners();
    }

    initPreviousNextListeners(): void {
        let me: this = this;

        jQuery(".btn.showmore").off("click").click(function (event: JQuery.Event): void {
            event.preventDefault();

            me.pageID++;
            me.getSchedule(false);
        });
    }

    initShowMoreBtn(): void {
        let me: this = this;

        jQuery('.show-more-btn').click(function (event) {
            event.preventDefault();
            jQuery('.show-more-hidden').addClass('show-more-visible');
            jQuery('.show-more-wrapper').remove();
        });
    }

    needToLoadNext(): void {
        let me: this = this,
            showMoreBtn: JQuery<HTMLElement> = jQuery(me.containerElement).find(".btn.showmore");
        if (!me.isQueryRunning && showMoreBtn.length === 1) {
            if (InViewPort.isAnyPartOfElementInViewport(showMoreBtn[0])) {
                me.pageID++;
                me.getSchedule(false);
            }
        }
    }

    jumpToPage(): void {
        let me: this = this,
            pageElement: JQuery<HTMLElement> = jQuery(me.containerElement).find(`.schedule-item.page-${me.pageID}:first`);

        if (me.pageID > 1 && pageElement.length > 0) {
            me.utilities.scrollToElement(pageElement);
        }
    }

    getSchedule(showFullScreenLoader:boolean = true): void {
        let me: this = this;

        if (me.isQueryRunning) {
            return;
        }

        let loaderContainerElement: JQuery < HTMLElement > = showFullScreenLoader ? jQuery(me.containerElement) : jQuery(me.containerElement).find(".showmore-container"),
            locationFilterElement: JQuery<HTMLElement> = jQuery("#filter-locations"),
            categoryFilterElement: JQuery<HTMLElement> = jQuery("#filter-categories"),
            location: string = locationFilterElement.val() as string,
            categoryID: number = categoryFilterElement.val() as number,
            liveOnline: boolean = jQuery("#filter-live-online").is(":checked"),
            ajaxUrl: string = me.constructAjaxUrl(me.action, categoryID, location, null, liveOnline, me.pageID),
            apiCallback:IApiCallback = {
                success: (data: any): void => {
                    jQuery(me.containerElement).find(`.${me.resultCls}`).html(data.html);
                    me.initContent();
                },
                complete: (textStatus: string) => {
                    jQuery(loaderContainerElement).removeClass(me.loadingCls);
                    me.initExpandMobile();
                    me.isQueryRunning = false;
                    me.filtersHasChanged();
                },
                error:(textStatus: string, errorThrown: string) => {
                    me.messageManager.showApiErrorMessage(errorThrown);
                    me.pageID--;
                },
                scope: me
            };

        me.isQueryRunning = true;
        jQuery(loaderContainerElement).addClass(me.loadingCls);
        me.apiClient.getData(ajaxUrl, apiCallback);
    }

    changeUrlAccordingToFiltersState(): void {
        let me: this = this,
            locationFilterElement: JQuery<HTMLElement> = jQuery("#filter-locations"),
            categoryFilterElement: JQuery<HTMLElement> = jQuery("#filter-categories"),
            locationUri: string = me.selectizeHelper.getUriDataAttributeForSelectedElement(locationFilterElement),
            categoryID: number = categoryFilterElement.val() as number,
            categoryUri: string = me.selectizeHelper.getUriDataAttributeForSelectedElement(categoryFilterElement),
            liveOnline: boolean = jQuery("#filter-live-online").is(":checked"),
            url: string = me.constructUrl(locationUri, categoryID, categoryUri, liveOnline, me.pageID);

        me.urlManager.pushState(null, document.title, url);
    }

    constructAjaxUrl(action: string, categoryID: number, location: string, templateID: number, liveOnline: boolean, pageID: number): string {
        let me: this = this,
            url: string = `${me.controllerUri}/${action}/`,
            parts: string[] = [];

        if (location && location !== "") {
            parts.push("location=" + me.urlManager.encodeUriComponent(location));
        }

        if (categoryID > 0) {
            parts.push("categoryID=" + categoryID);
        }

        if (templateID > 0) {
            parts.push("templateID=" + templateID);
        }

        if (liveOnline) {
            parts.push("liveOnline=true");
        }

        if (pageID > 1) {
            parts.push("pageID=" + pageID);
        }

        if (parts.length > 0) {
            url += "?" + parts.join("&");
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    constructUrl(location: string, categoryID: number, categoryUri: string, liveOnline: boolean, pageID: number): string {
        let me: this = this,
            url: string = `${me.controllerUri}`;

        // the order of this is important because the routes are set up in the same order

        if (location && location !== "") {
            url += `/${me.filterPrefixConfig.location}-${me.urlManager.encodeUriComponent(location)}`;
        }

        if (categoryID > 0) {
            url += `/${me.filterPrefixConfig.category}-${categoryID}-${me.urlManager.encodeUriComponent(categoryUri)}`;
        }

        if (pageID > 1) {
            url += `/${me.filterPrefixConfig.paging}-${pageID}`;
        }

        if (liveOnline) {
            url += `/${me.filterPrefixConfig.liveOnline}`;
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    clearFilters(): void {
        let me: this = this;

        me.selectizeHelper.setValue("#filter-locations", "", true);
        me.selectizeHelper.setValue("#filter-categories", "", true);
        jQuery("#filter-live-online").prop("checked", false);
        jQuery("#btn-live-online").attr("aria-checked", "false");
    }

    timeZoneHasChanged(): void {
        let me: this = this;

        let timeZoneID: string = jQuery("#filter-timezones").val() as string;

        me.cookieManager.setCookie("TimeZoneID", timeZoneID.toString(), 365);   
    }

    filtersHasChanged(changeUrl:boolean = true): void {
        let me: this = this;

        let location: string = jQuery("#filter-locations").val() as string;
        let categoryID: number = jQuery("#filter-categories").val() as number;
        let liveOnline: boolean = jQuery("#filter-live-online").is(":checked") as boolean;

        let hasLocation: boolean = (location && location !== "");
        let hasCategoryID: boolean = (categoryID > 0);

        me.selectizeHelper.applyClassIf("#filter-locations", "applied", hasLocation);
        me.selectizeHelper.applyClassIf("#filter-categories", "applied", hasCategoryID);

        me.utilities.applyClassIf("#filters", "filter-applied", (hasLocation || hasCategoryID || liveOnline));

        if (changeUrl) {
            me.changeUrlAccordingToFiltersState();
        }
    }
}

