import { InViewPort } from "../Utilities/InViewPort";

export class Utilities {

    chroma: any;

    constructor(chroma) {
        let me: this = this;

        me.chroma = chroma;
    }

    escapeHtml(unsafe): string {
        if (unsafe)
            return unsafe
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");

        return unsafe;
    }

    removeTrailingSlash(url: string) {
        if (url && url.length > 0) {
            let last: number = url.length - 1;
            if (url.charAt(last) === '/') {
                return url.substring(0, last);
            }
        }
        return url;
    }

    ensureTrailingSlash(url: string) {
        if (url != null && url.charAt(url.length - 1) != "/") {
            url += "/";
        }

        return url;
    }

    debounce(func: Function, wait: number, immediate: boolean): Function {
        let timeout:any;
        return function():void {
            let args:IArguments = arguments,
                later:Function = () => {
                    timeout = null;
                    if (!immediate) {
                        func(args);
                    }
                },
                callNow: boolean = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);

            if (callNow) {
                func(args);
            }
        };
    }

    applyClassIf(selector: string, cls: string, condition: boolean):void {
        let me: this = this;

        if (selector !== "" && cls !== "") {
            if (condition) {
                jQuery(selector + ":not(." + cls + ")").addClass(cls);
            } else {
                jQuery(selector + "." + cls).removeClass(cls);
            }
        }
    }

    expandMobile(event: JQuery.Event, element: JQuery<HTMLElement>): void {
        let target:JQuery<HTMLElement> = jQuery(event.target);

        if (!jQuery(target).is("a:not(.btn-expand), button")) {
            if (jQuery(event.target).closest(".schedule-date").length > 0) { return; }
            event.preventDefault();
            element.closest(".event, .catalogue-item, .online-activity, .schedule-item, .online-activity-item").toggleClass("expanded");
        }
    }

    isTouchEnabled():boolean {
        return ("ontouchstart" in window || navigator.maxTouchPoints > 1);
    }

    animateIn(className: string):void {
        let elementsInViewport:JQuery<HTMLElement> = jQuery(className).filter(function (index:number):boolean {
            return InViewPort.isElementMostlyVisible(this);
        });

        if (elementsInViewport.length > 0) {
            setTimeout(() => {
                jQuery(elementsInViewport).addClass("is-in-viewport");
            }, 0);
        }
    }

    initHeaderBackgrouncCls(): void {
        let me: this = this,
            headerSectionContainer: JQuery<HTMLElement> = jQuery("header.bg-image section.container");

        me.initBackgroundCls(headerSectionContainer);
    }

    initBackgroundCls(container: JQuery<HTMLElement>): void {
        let me: this = this;

        if (container.length === 1) {
            let heading: JQuery<HTMLElement> = container.find(".page-title"),
                subHeading: JQuery<HTMLElement> = container.find(".page-subheading"),
                summary: JQuery<HTMLElement> = container.find(".page-summary");

            if (heading && heading.length > 0) {
                heading.addClass(me.getTextBackgroundClsByColour(heading.css("color")));
            }

            if (subHeading && subHeading.length > 0) {
                subHeading.addClass(me.getTextBackgroundClsByColour(subHeading.css("color")));
            }

            if (summary && summary.length > 0) {
                summary.addClass(me.getTextBackgroundClsByColour(summary.css("color")));
            }
        }
    }

    getTextBackgroundClsByColour(colour: string): string {
        let me: this = this;

        return me.chroma(colour).luminance() > 0.5 ? "bg-dark" : "bg-light";
    }

    initPopOpvers():void {
        let me: this = this;

        jQuery('.popover-trigger').click(function() {
            $('.popover-trigger').each(function() {
                jQuery(this).popover('hide');
            }); 
        });

        jQuery('.popover-trigger').popover({ 
            trigger: "hover",
            placement: 'top',
            html: true,
            content: function() {
                return jQuery(this).find(".popover-content").html();
            },
            title: function() {
                return jQuery(this).find(".popover-title").html();
            }
        });
    }

    scrollToElement(element): void {
        let top:number = element.position().top == 0 ? element.offset().top - 100 : element.position().top;

        $('html, body').animate({
            'scrollTop': top - 30
        });
    }

    toggleCheckboxFor(checkboxID: string, buttonElement: HTMLElement): void {
        let checkbox: HTMLInputElement = document.getElementById(checkboxID) as HTMLInputElement;
        checkbox.checked = !checkbox.checked;
        buttonElement.setAttribute('aria-checked', checkbox.checked.toString());
    }
}
