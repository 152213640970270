﻿import { Utilities } from "../Utilities/Utilities";
import { SelectizeHelper } from "../Utilities/SelectizeHelper";
import { UrlManager } from "../Utilities/UrlManager";
import { SearchList } from "./SearchList";
import { EventTileFlip } from "../Utilities/EventTileFlip";

export class HomePage {
    readonly containerCls: string = "home-page";
    readonly linkItemCls :string = "link-item";
    readonly upcomingItemCls :string = "event-card";
    readonly searchWidgetCls: string = "search-widget";

    containerElement: JQuery<HTMLElement>;

    utilities: Utilities;
    selectizeHelper: SelectizeHelper;
    searchList: SearchList
    chroma: any;

    constructor(utilities: Utilities, searchList: SearchList, selectizeHelper: SelectizeHelper, chroma: any) {
        let me:this = this,
            element:JQuery<HTMLElement> = jQuery(`.${me.containerCls}`);

        me.utilities = utilities;
        me.searchList = searchList;
        me.selectizeHelper = selectizeHelper;
        me.chroma = chroma;

        if (element.length === 1) {
            me.containerElement = element;
            me.initHomePage();
            me.initScrollEvent();
        }
    }

    initScrollEvent():void {
        let me:this = this,
            initAnimation:Function = me.utilities.debounce(():void => {
                me.initAnimation();
            }, 50, false);

        window.onscroll = (ev: UIEvent): any => {
            initAnimation();
       };
    }

    initHomePage():void {
        let me:this = this;

        jQuery(".banner-content").css("padding-top", jQuery(".header.home").outerHeight());

        me.selectizeHelper.initSelect();

        EventTileFlip.initUpcomingEvents(me.upcomingItemCls);
        me.initAnimation();
        me.initFirstSection();
        me.initSearchWidget();
        
        jQuery(window).on("load", (): void => {
            me.initLinkSection();
        });
    }

    initLinkSection(): void {
        let me: this = this,
            linkItems: JQuery<HTMLElement> = jQuery(".link-items");

        // find heighest
        linkItems.each((index: number, linkGroup: HTMLElement): void => {
            let imageMaxHeight: number = 0,
                headingMaxHeight: number = 0;

            jQuery(linkGroup).find(`.${me.linkItemCls}`).each((i: number, element: HTMLElement): void => {
                 var imageHeight: number = jQuery(element).find(".link-image").height(),
                    headingHeight: number = jQuery(element).find("h2").height();

                if (imageHeight > imageMaxHeight) {
                    imageMaxHeight = imageHeight;
                }

                if (headingHeight > headingMaxHeight) {
                    headingMaxHeight = headingHeight;
                }
            })

            jQuery(linkGroup).find(".link-image").height(imageMaxHeight);
            jQuery(linkGroup).find("h2").height(headingMaxHeight);
        });
    }




    initSearchWidget(): void {
        let me: this = this,
            searchWidgetElement: JQuery<HTMLElement> = jQuery(`.${me.searchWidgetCls}`),
            searchInput: JQuery<HTMLElement> = searchWidgetElement.find("input"),
            submitBtn: JQuery<HTMLElement> = searchWidgetElement.find("button.inline-search-btn");

        submitBtn.click((): void => {
            me.runSearch(searchInput.val() as string);
        });

        searchInput.keyup((event: JQuery.Event): void => {
            if (event.which === 13) {
                me.runSearch(searchInput.val() as string);
            }
        });
    }

    runSearch(searchTerm: string): void {
        let me: this = this;

        document.location.href = me.searchList.constructUrl(searchTerm, "", 0, "", false, false);
    }

    initFirstSection(): void {
        let me: this = this,
            banner: JQuery<HTMLElement> = me.containerElement.find(".banner");

        if (banner.length === 1 && banner.hasClass("bg-image")) {
            let heading: JQuery<HTMLElement> = banner.find("h1"),
                subHeading: JQuery<HTMLElement> = banner.find("h3"),
                summary: JQuery<HTMLElement> = banner.find("h5");

            heading.addClass(me.getTextBackgroundClsByColour(heading.css("color")));
            if (subHeading && subHeading.length > 0) {
                subHeading.addClass(me.getTextBackgroundClsByColour(subHeading.css("color")));
            }
            if (summary && summary.length > 0) {
                summary.addClass(me.getTextBackgroundClsByColour(summary.css("color")));
            }

            me.initListeners();
        }
    }

    getTextBackgroundClsByColour(colour:string): string {
        let me: this = this;

        return me.chroma(colour).luminance() > 0.5 ? "bg-dark" : "bg-light";
    }

    initAnimation():void {
        let me:this = this;

        me.utilities.animateIn(`.${me.linkItemCls}`);
        me.utilities.animateIn(`.${me.upcomingItemCls}`);
    }

    initListeners(): void {
        let me: this = this;
        jQuery(".banner-bottom-link").click(function (event: JQuery.Event): void {
            $("html,body").animate({ scrollTop: $(".banner").outerHeight() - 60 }, 500)
        })
    }
}

