﻿import { Utilities } from "./Utilities";

export class SelectizeHelper {
    utilities: Utilities;

    constructor(utilities: Utilities) {
        this.utilities = utilities;
    }

    initSelect(config?:object):void {
        if (config == null) {
            config = {
                create: false,
                allowEmptyOption: true,
                openOnFocus: false,
                onInitialize: function () {
                    var that = this;

                    this.$control.on("click", function () {
                        that.ignoreFocusOpen = true;
                        setTimeout(function () {
                            that.ignoreFocusOpen = false;
                        }, 50);
                    });
                },

                onFocus: function () {
                    if (!this.ignoreFocusOpen) {
                        this.open();
                    }
				},

				onDropdownOpen: function () {
					this.$dropdown.css({
						"width": 'auto',
						"min-width": this.$control.outerWidth(),
					});
				},
            };
        }

        jQuery("select.selectize:not(.selectized)").selectize(config);
    }

    setValue(selector: string, value: string, silent: boolean):void {
        let me: this = this,
            select: JQuery<HTMLElement> = jQuery(selector),
            selectizeElement: HTMLElement = select.length > 0 ? jQuery(selector).selectize()[0] : null;

        if (selectizeElement != null) {
            selectizeElement.selectize.setValue(value, silent);
        }
    }

    applyClassIf(selector: string, cls: string, condition: boolean):void {
        let me: this = this;

        me.utilities.applyClassIf(selector + " + .selectize-control", cls, condition);
    }

    getUriDataAttributeForSelectedElement(element: JQuery<HTMLElement>): string {
        let me: this = this,
            selectize: any = element && element.length > 0 ? element[0].selectize : null,
            uri: string = "",
            value: string = selectize ? selectize.items[0] : null;

        if (value && value !== "") {
            if (selectize.options.hasOwnProperty(value) && selectize.options[value].hasOwnProperty("uri")) {
                uri = selectize.options[value].uri;
            } else {
                uri = value;
            }
        }

        return uri;
    }
}
