﻿import { Utilities } from "../Utilities/Utilities";
import { SelectizeHelper } from "../Utilities/SelectizeHelper";

export class EventTemplate {
    readonly containerCls :string = "event-list";
    readonly itemCls:string = "event";
    readonly mobileWidthBreakpoint:number = 768;

    utilities: Utilities;
	selectizeHelper: SelectizeHelper;
	cookieManager: ICookieManager;

    containerElement:JQuery<HTMLElement>;

    constructor(utilities: Utilities, selectizeHelper: SelectizeHelper, cookieManager: ICookieManager) {
        let me:this = this,
            element:JQuery<HTMLElement> = jQuery(`.${me.containerCls}`);

        me.utilities = utilities;
		me.selectizeHelper = selectizeHelper;
		me.cookieManager = cookieManager;

        if (element.length === 1) {
            me.containerElement = element;
        }
    }

    initEventList():void {
        let me:this = this,
            showMoreWrp:JQuery<HTMLElement> = me.containerElement.find(".show-more-wrapper"),
            headerWrp:JQuery<HTMLElement> = me.containerElement.find(".arlo-header");

        if (showMoreWrp.length > 1 || !jQuery(showMoreWrp).is(":last-child")) {
            showMoreWrp.first().remove();
        }

        if (headerWrp.find("[class*='arlo-filter-']").length < 1) {
            headerWrp.addClass("no-filter");
        } else {
            headerWrp.removeClass("no-filter");
        }

        $('.similar-course > a').click(function (event) {
            event.preventDefault();
            $(this).parent().toggleClass('expanded');
		});

        me.calculateEventHeights();
        me.initCollapsableElements();
        me.initCollapseButton();
        me.initSessionPopUp();
        me.selectizeHelper.initSelect();

        me.initWalkMeHelpers();
    }

    initSessionPopUp():void {
        let me:this = this;
        me.containerElement.find(".show-sessions-trigger").off("click").click((event:JQuery.Event):void => {
            event.preventDefault();
            jQuery(event.currentTarget).closest(`.${me.itemCls}`).find(".sessions").modal();
        });
	}

	initTimeZones(): void {
		let me: this = this;
		$(".arlo-filter-timezone select").change((): void => {
			me.timeZoneHasChanged();
		});
	}

    initCollapseButton(): void {
        let me: this = this;

        if (window.innerWidth >= 768) {
            let buttonWidth: number = me.containerElement.find(".events-1 .buttons").outerWidth();
            me.containerElement.find(".events-1 .btn-expand.desktop").css("right", buttonWidth + 30);
        }
    }

    initCollapsableElements():void {
        let me:this = this;
        
        if (window.innerWidth >= 768) {
            me.containerElement.find(`.${me.itemCls} .event-container`)
			.off("click mouseleave")
            .on({
				mouseleave: (e:JQuery.Event):void => {
                    jQuery(e.currentTarget).closest(`.${me.itemCls}`).removeClass("expanded");
                },
                click: (e:JQuery.Event):void => {
                    let eventItem:JQuery<HTMLElement> = jQuery(e.currentTarget).closest(`.${me.itemCls}`);
                    if (!eventItem.hasClass("expanded")) {
                        eventItem.addClass("expanded");
                    }
                }
            });
        } else {
            jQuery(".event-content, .online-activity .btn-expand.mobile, .event .btn-expand.mobile")
                .off("click keydown")
                .on({
                    "click keydown": (event: JQuery.Event): void => {
                        if (event.type === "keydown" && event.key !== "Enter") {
                            return;
                        }
                        me.utilities.expandMobile(event, jQuery(event.currentTarget));
                    }
                })
        }

        // Opens or closes the event component when the expand button is clicked. Also works with keyboard navigation.
        me.containerElement.find(`.${me.itemCls} .btn-expand`)
        .off("click keydown")
        .on({
            "click keydown": (event: JQuery.Event): void => {
                // Only allows the enter key to expand the event component. Other keydown types are ignored.
                if (event.type === "keydown" && event.key !== "Enter") {
                    return;
                }

                event.stopPropagation();
                let eventItem: JQuery<HTMLElement> = jQuery(event.currentTarget).closest(`.${me.itemCls}`);
                
                if (!eventItem.hasClass("expanded")) {
                    eventItem.addClass("expanded");
                } else {
                    eventItem.removeClass("expanded");
                }
            }
        })
    }

    initWalkMeHelpers(): void {
        let me: this = this;

        // Add a class to the last event created by the current user
        var lastEvtId = me.cookieManager.getCookie('_ls_events_lasteventid');
        if (typeof lastEvtId !== 'undefined') {
            var lastEventEl = me.containerElement.find('[data-eventid="' + lastEvtId + '"]');
            if (lastEventEl.length) {
                lastEventEl.addClass("lasteventcreated");
            }
        }
    }

    calculateEventHeights():void {
        let me:this = this,
            highest:number = 0,
            items: JQuery<HTMLElement>;

        if (window.innerWidth >= me.mobileWidthBreakpoint) {
            items = me.containerElement.find(`.${me.itemCls}`);
            items.height("auto");

            me.containerElement.find(`.${me.itemCls}:not(.show-more-hidden), .${me.itemCls}.show-more-visible`)
            .each((i:number, event:HTMLElement):void => {
                if (jQuery(event).height() > highest) {
                    highest = jQuery(event).height();
                }
            });

            items.height(highest);
        }
	}

	timeZoneHasChanged(): void {
		let me: this = this;

		let timeZoneID: string = jQuery(".arlo-filter-timezone select").val() as string;

		me.cookieManager.setCookie("TimeZoneID", timeZoneID.toString(), 365);
	}
}

